import { generateMapByOpts, mapHelper } from 'common/utils'

// 状态
const deviceStatusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '在用',
    value: 1
  }, {
    text: '停用',
    value: 0
  }, {
    text: '已报废',
    value: 2
  }
]

const deviceStatusMap = generateMapByOpts(deviceStatusOps)
// 修改类型
const editOps = [
  {
    text: '责任人',
    value: 1
  }, {
    text: '维保单位',
    value: 2
  }, {
    text: '巡检负责人',
    value: 3
  }, {
    text: '维保负责人',
    value: 4
  }, {
    text: '维保频次',
    value: 5
  }, {
    text: '巡检频次',
    value: 6
  },
  {
    text: '巡检执行人',
    value: 8
  },
  {
    text: '维保执行人',
    value: 9
  },
  {
    text: '其他',
    value: 7
  },
  
]
// 项目范围
const projectAreaOps = [
  {
    text: '指定项目',
    value: 1
  }, {
    text: '全部项目',
    value: 2
  }
]
// 产地
const originOps = [
  {
    text: '请选择',
    value: undefined
  }, {
    text: '进口',
    value: '进口'
  }, {
    text: '国产',
    value: '国产'
  }
]
// 是否维保
const maintenanceOps = [
  {
    text: '是',
    value: 1
  }, {
    text: '否',
    value: 0
  }
]
// 维保方式
const maintenanceTypeOps = [
  {
    text: '请选择',
    value: undefined
  }, {
    text: '蓝牙',
    value: 1
  }, {
    text: '二维码',
    value: 2
  }
]
// 维保周期
const periodOps = [
  {
    text: '周',
    value: 1
  }, {
    text: '半月',
    value: 2
  }, {
    text: '月',
    value: 3
  }, {
    text: '季度',
    value: 4
  }, {
    text: '半年',
    value: 5
  }, {
    text: '年',
    value: 6
  }
]
const {
  map: periodOpsMap,
  setOps: periodOpsSetOps
} = mapHelper.setMap(periodOps)
// 维保单位分类
const maintCompanyTypeOps = [
  {
    text: '请选择',
    value: undefined
  }, {
    text: '原厂维保',
    value: 1
  }, {
    text: '外委维保',
    value: 2
  }, {
    text: '集团维保',
    value: 3
  }
]
// 外包类型
const outsourcingTypeOps = [
  {
    text: '请选择',
    value: undefined
  }, {
    text: '质保期内',
    value: '质保期内'
  }, {
    text: '无外包',
    value: '无外包'
  }, {
    text: '甲方外包',
    value: '甲方外包'
  }, {
    text: '清包',
    value: '清包'
  }, {
    text: '中包',
    value: '中包'
  }, {
    text: '大包',
    value: '大包'
  }
]
// 巡检周期
const patrolPeriodOps = [
  {
    text: '日',
    value: 0
  }, {
    text: '周',
    value: 1
  }, {
    text: '半月',
    value: 2
  }, {
    text: '月',
    value: 3
  }, {
    text: '季度',
    value: 4
  }, 
]

// 巡检频次
const periodForm = [
  {
    text: '日',
    value: 0
  }, {
    text: '周',
    value: 1
  }, {
    text: '半月',
    value: 2
  }, {
    text: '月',
    value: 3
  }, {
    text: '季度',
    value: 4
  }, {
    text: '半年',
    value: 5
  }
]
// 巡查间隔
const timespanUnit = [
  {
    text: '小时',
    value: 1
  },
  {
    text: '日',
    value: 2
  }
]

// 新增 需求OS-1494
// 巡检/维保方式
const patrolMaintenanceType = [
  {
    text: '蓝牙',
    value: 1
  },
  {
    text: '二维码',
    value: 2
  }
]

const {
  map: patrolMaintenanceTypeMap,
  setOps: patrolMaintenanceTypeOps
} = mapHelper.setMap(patrolMaintenanceType)
const {
  map: periodFormMap,
  setOps: periodFormOps
} = mapHelper.setMap(periodForm)
const {
  map: timespanUnitMap,
  setOps: timespanUnitOps
} = mapHelper.setMap(timespanUnit)
const workTypeSearch = [
  {
    text: '未设置',
    value: 0
  },
  {
    text: '蓝牙',
    value: 1
  },
  {
    text: '二维码',
    value: 2
  }
]

const {
  map: workTypeSearchMap,
  setOps: workTypeSearchOps
} = mapHelper.setMap(workTypeSearch)

// 是否巡检/维保 搜索页面
const patrolMaintenanceSearchStatus = [
  {
    text: '未设置',
    value: -1
  },
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: patrolMaintenanceSearchStatusMap,
  setOps: patrolMaintenanceSearchStatusOps
} = mapHelper.setMap(patrolMaintenanceSearchStatus)

export {
  deviceStatusOps,
  deviceStatusMap,
  editOps,
  projectAreaOps,
  originOps,
  maintenanceOps,
  maintenanceTypeOps,
  periodOps,
  periodOpsMap,
  periodOpsSetOps,
  maintCompanyTypeOps,
  outsourcingTypeOps,
  patrolPeriodOps,
  // 新增
  patrolMaintenanceTypeMap,
  patrolMaintenanceTypeOps,
  workTypeSearchMap,
  workTypeSearchOps,
  patrolMaintenanceSearchStatusMap,
  patrolMaintenanceSearchStatusOps,
  periodFormMap,
  periodFormOps,
  periodForm,
  timespanUnit,
  timespanUnitMap,
  timespanUnitOps
}
