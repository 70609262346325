let baseurl = 'http://192.168.100.103:7020/manage/'
let oldurl = 'http://192.168.100.103:7020/old/'
// 获取列表
const getListURL = `${API_CONFIG.baseURL}smDeviceAction!list.action`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}orderSettle/exportOrderSettle`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 获取分类列表
const getCategoryListURL = `${API_CONFIG.baseURL}smDeviceAction!categoryList.action`
// 获取设备房列表
const getAllDeviceRoomURL = `${API_CONFIG.baseURL}smDeviceAction!getAllDeviceRoom.action`
// 获取维保单位列表
const getMaintenanceUnitURL = `${API_CONFIG.baseURL}smDeviceAction!queryCategoryCommon.action?type=3`
// 获取通用内容列表
const getContentCommonURL = `${API_CONFIG.baseURL}smDeviceAction!queryCategoryCommon.action?exclude=3`
// 生成二维码
const getGenerateQrcodeURL = `${API_CONFIG.baseURL}smDeviceAction!generateQrcode.action`
// 批量生成二维码
const batchGenerateQrcodeURL = `${API_CONFIG.baseURL}smDeviceAction!batchGenerateQrcode.action`
const batchGenerateQrcodeURLNew = `${API_CONFIG.butlerBaseURL}device/batchGenerateQrcode`
// 生成巡检日志
const generateDevicePatrolSingleURL = `${API_CONFIG.butlerBaseURL}devicePatrol/generateDevicePatrolSingle`
// 导出设备列表
const exportDeviceToExcelURL = `${API_CONFIG.butlerBaseURL}device/exportDeviceToExcel`
// 导出二维码
const exportQrcodeExcelURL = `${API_CONFIG.butlerBaseURL}device/base/qrcode/export`
// 批量删除
const batchDeleteDeviceURL = `${API_CONFIG.baseURL}smDeviceAction!batchDeleteDevice.action`
// 导入关联二维码
const importQrcodeURL = `${API_CONFIG.baseURL}smDeviceAction!importQrcode.action`
// 导入url
const uploadUrl = `${API_CONFIG.uploadURL}?module=excel`
// 导入台账
const importExcelURL = `${API_CONFIG.butlerBaseURL}device/account/import`
// 基础信息新增
const saveDeviceBaseURL = `${API_CONFIG.baseURL}smDeviceAction!saveDeviceBase.action`
// 基础信息更新
const updateDeviceBaseURL = `${API_CONFIG.baseURL}smDeviceAction!updateDeviceBase.action`
// 参数信息编辑
const saveDeviceParamsURL = `${API_CONFIG.baseURL}smDeviceAction!saveDeviceParams.action`
// 维保信息编辑
const saveDeviceMaintURL = `${API_CONFIG.baseURL}smDeviceAction!saveDeviceMaint.action`
// 巡检信息编辑
const saveDevicePatrolURL = `${API_CONFIG.butlerBaseURL}devicePatrol/patrol/save`
// 上传图片地址
const uploadimgURL = `${API_CONFIG.uploadURL}?module=device`
// 模糊搜索 2级、3级分类列表
const getCategoryListByParentURL = `${API_CONFIG.baseURL}smDeviceAction!getCategoryListByParent.action`
// 获取团组列表
// const getBlockNameFromCommunityURL = `${API_CONFIG.baseURL}serverRoomAction!queryBlockNameFromCommunity.action`
// 获取蓝牙列表
const getBlueteethURL = `${API_CONFIG.baseURL}smDeviceAction!queryBlueteeth.action`
// 获取详情
const getDetailURL = `${API_CONFIG.baseURL}smDeviceAction!queryOne.action`
// 批量修改(负责人)
const batchUpdateChargerURL = `${API_CONFIG.baseURL}smDeviceAction!batchUpdateCharger.action`
// 批量修改（维单位）
const batchUpdateMaintCompanyURL = `${API_CONFIG.baseURL}smDeviceAction!batchUpdateMaintCompany.action`
// 批量修改（维保负责人）
const batchUpdateMaintChargerURL = `${API_CONFIG.butlerBaseURL}device/batch/update/maint/charger`
// 批量修改（巡检负责人）
const batchUpdatePatrolChargerURL = `${API_CONFIG.butlerBaseURL}device/batch/update/patrol/charger`
//批量修改（巡检执行人）
const updatePatrolInspectionExecutorURL = `${API_CONFIG.butlerBaseURL}device/batch/update/patrol/executor`
//批量修改（维保执行人）
const updateMaintenanceExecutorURL = `${API_CONFIG.butlerBaseURL}device/batch/update/maint/executor`
//
// 批量修改（维保频次）
const batchUpdateMaintURL = `${API_CONFIG.butlerBaseURL}deviceMaint/update/maint/params`
// 批量修改（巡检频次）
const batchUpdatePatrolURL = `${API_CONFIG.butlerBaseURL}devicePatrol/batch/update/patrol/period`
// 生成物管台账
const generateOneCommunityDeviceDBURL = `${API_CONFIG.baseURL}smDeviceAction!generateOneCommunityDeviceDB.action`
// 获取维保公共内容
const queryCommonContentURL = `${API_CONFIG.baseURL}smDeviceAction!queryCommonContentByCategoryId.action`
// 责任人
const getUsernameURL = `${API_CONFIG.butlerBaseURL}user/search/community/username`
// 获取巡检频次相关
const getPeriodDetailURL = `${API_CONFIG.butlerBaseURL}devicePatrol/detail`
// 获取设备树
const getTreeDataURL = `${API_CONFIG.butlerBaseURL}device/getDeviceCategoryTree`
// 生成维保计划和批量生成维保计划
const generateMaintLogURL = `${API_CONFIG.butlerBaseURL}deviceMaint/generateMaintLog`
// 批量更新巡检日志,和生成单个巡检日志不是同一个接口
const generateDevicePatrolBatchURL = `${API_CONFIG.butlerBaseURL}devicePatrol/generateDevicePatrolBatch`
// 批量修改设施设备信息更新维保频次


// 批量修改-其他（editType为7）
// /device/updateOthers
const updateOthersURL = `${API_CONFIG.butlerBaseURL}device/updateOthers`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getCategoryListURL,
  getAllDeviceRoomURL,
  getMaintenanceUnitURL,
  getContentCommonURL,
  getGenerateQrcodeURL,
  batchGenerateQrcodeURL,
  generateDevicePatrolSingleURL,
  exportDeviceToExcelURL,
  exportQrcodeExcelURL,
  batchDeleteDeviceURL,
  importQrcodeURL,
  uploadUrl,
  importExcelURL,
  saveDeviceBaseURL,
  updateDeviceBaseURL,
  saveDeviceParamsURL,
  saveDeviceMaintURL,
  saveDevicePatrolURL,
  uploadimgURL,
  getCategoryListByParentURL,
  // getBlockNameFromCommunityURL,
  getBlueteethURL,
  getDetailURL,
  batchUpdateChargerURL,
  batchUpdateMaintCompanyURL,
  batchUpdateMaintChargerURL,
  batchUpdatePatrolChargerURL,
  batchUpdateMaintURL,
  batchUpdatePatrolURL,
  generateOneCommunityDeviceDBURL,
  queryCommonContentURL,
  getUsernameURL,
  getPeriodDetailURL,
  getTreeDataURL,
  generateMaintLogURL,
  generateDevicePatrolBatchURL,
  batchGenerateQrcodeURLNew,
  updateOthersURL,
  updatePatrolInspectionExecutorURL,
  updateMaintenanceExecutorURL
}
